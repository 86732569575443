<template>
    <div>
        <div v-for="option in options" :key="option" class="acte_option no-gutters row align-items-center">
            <div class="col-auto pr-2">
				<font-awesome-icon v-if="icon" :icon="icon" />
			</div>
            <div class="col"><input type="text" :value="option" :placeholder="placeholderExample" readonly></div>
        </div>
    </div>
</template>

<script type="text/javascript">

	export default {
		name: "ViewValues",
        props: [
            'values',
            'icon',
            'type'
        ],
        data() {
            return {
                options: [],
                types_correspondance: {
                    "DAT": "ex_reponse_date",
                    "NUM": "ex_reponse_int",
                    "TXT": "ex_reponse_str",
                    "LTX": "ex_reponse_long",
                }
            }
        },
        mounted() {
            if(this.values.data) {
                this.options = this.values.data
            }
        },
        watch: {
            'values' (val) {
                if(val && val.data) {
                    this.options = this.values.data
                }
            }
        },
        computed: {
            placeholderExample() {
                const trad = this.types_correspondance[this.type]

                if(trad) {
                    return this.getTrad('question.'+trad)
                }
                return this.getTrad('question.ex_reponse_str')
            },
        }
    }
</script>
